span.input-group-addon.kv-date-remove {
    padding: 12px 20px 0px 8px;
    font-size: 16px;
}

span.input-group-addon.kv-date-picker {
    padding: 12px 20px 0px 8px;
    font-size: 16px;
}

.react-select__value-container{
    flex-wrap: nowrap !important;
    text-overflow: "ellipsis" !important;
  }
  
.react-select__multi-value{
    min-width: 40% !important;
}

.sticky {
    position: -webkit-sticky;
    /* Safari */
    position: sticky !important;
    top: 4.5em;
}

.navigate-icon {
    font-size: 15px;
}

.flex-container,
.flex-container2 {
    display: flex;
    flex-direction: row;
}

.flex-item-left {
    flex: 50%;
}

.flex-item-right {
    flex: 50%;

}

button.btn.btn-tool {
    color: black !important;
}

@media (max-width: 800px) {
    .flex-container {
        flex-direction: column;
    }
}

@media (min-width: 700px) {
    .content-wrapper {
        min-width: 1080px !important;
        overflow: auto;
    }
}

.fade {
    opacity: 1;
}

.card-header {
    border-bottom: 0px;
}

.card.lvl1 {
    background-color: #E8F3FF;
}

.card.lvl2 {
    background-color: #E8F3EE;
}

.card.lvl3 {
    background-color: #E8F3DD;
}

.pointerEventsStyle {
    pointer-events: none;
    background-color: rgb(233, 236, 239) !important
}

.disableHref {
    pointer-events: none;
    color: black;

}

.flatpickr-input[readonly]:not(.pointerEventsStyle,.pointerEventsStyleDocDate) {
    background-color: white !important;
}

.qt-table-label {
    font-size: 25px;
    font-weight: bold;
}

img {
    vertical-align: middle;
    border-style: none;
    width: 200px;
}

.dashboard>.dropdown-toggle::after {
    content: none;
}

.navbar {
    margin-bottom: 0px;
}

.brand-text:hover {
    color: black;
}

.brand-link:hover {
    color: black;
}

@media screen and (max-width: 1700px) {
    .leftList {
        left: auto !important;
        right: 168px;
    }

    .leftList2 {
        left: auto !important;
        right: 159px;
    }

    .disabledLink {
        pointer-events: none !important;
    }


}

@media (min-width: 768px) {

    body:not(.sidebar-mini-md):not(.sidebar-mini-xs):not(.layout-top-nav) .content-wrapper,
    body:not(.sidebar-mini-md):not(.sidebar-mini-xs):not(.layout-top-nav) .main-footer,
    body:not(.sidebar-mini-md):not(.sidebar-mini-xs):not(.layout-top-nav) .main-header {
        margin-left: 0px !important;
    }

}

html,
body {
    height: 100%;
    scroll-behavior: smooth;
    font-size: .95rem !important;
}

body.blank {
    display: flex;
    padding: 40px 0;
}

.wrap {
    min-height: 100%;
    height: auto;
    margin: 0 auto -60px;
    padding: 0 0 60px;
}

body.blank .wrap {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 400px;
    margin: -40px auto;
    padding: 15px;
}

body.blank .wrap>div {
    width: 100%;
}

.wrap>.container {
    padding: 70px 15px 20px;
}

.footer {
    height: 60px;
    background-color: #f5f5f5;
    border-top: 1px solid #ddd;
    padding-top: 20px;
}

.jumbotron {
    text-align: center;
    background-color: transparent;
}

.jumbotron .btn {
    font-size: 21px;
    padding: 14px 24px;
}

.not-set {
    color: #c55;
    font-style: italic;
}

/* add sorting icons to gridview sort links */
a.asc:after,
a.desc:after {
    position: relative;
    top: 1px;
    display: inline-block;
    font-family: 'Glyphicons Halflings';
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    padding-left: 5px;
}

a.asc:after {
    content:
        /*"\e113"*/
        "\e151";
}

a.desc:after {
    content:
        /*"\e114"*/
        "\e152";
}

.sort-numerical a.asc:after {
    content: "\e153";
}

.sort-numerical a.desc:after {
    content: "\e154";
}

.sort-ordinal a.asc:after {
    content: "\e155";
}

.sort-ordinal a.desc:after {
    content: "\e156";
}

.grid-view th,
.grid-view td:last-child {
    white-space: nowrap;
}

.grid-view .filters input,
.grid-view .filters select {
    min-width: 50px;
}

.hint-block {
    display: block;
    margin-top: 5px;
    color: #999;
}

.error-summary {
    color: #a94442;
    background: #fdf7f7;
    border-left: 3px solid #eed3d7;
    padding: 10px 20px;
    margin: 0 0 15px 0;
}

/* align the logout "link" (button in form) of the navbar */
.nav li>form>button.logout {
    padding: 15px;
    border: none;
}

@media(max-width:767px) {
    .nav li>form>button.logout {
        display: block;
        text-align: left;
        width: 100%;
        padding: 10px 15px;
    }
}

.nav>li>form>button.logout:focus,
.nav>li>form>button.logout:hover {
    text-decoration: none;
}

.nav>li>form>button.logout:focus {
    outline: none;
}

/* .wrapper {
    width: fit-content;
} */

.fade.in {
    opacity: 1 !important;
}


/* start : booking confirmation style */
.box {
    display: flex;
}

.left-form {
    /* float: left; */
    flex: 1;
    /* border: 1px dashed #f0f; */
}

.shortcut-buttons {
    flex: 0 0 50px;
    /* do not grow, do not shrink,*/
    background-color: white;
    margin-left: 1px;
}

.navigate-icon {
    width: 100%;
    padding: 10px;
    text-align: center;
}

.navigate-icon:hover {
    background-color: #f2f2f2;
}

.holder {
    position: relative;
}

.dropdownTable {
    position: absolute;
    border: 1px solid black;
    border-radius: 5px;
    background-color: white;
    z-index: 800;
}

.cardMaxHeight {
    max-height: 650px;
    overflow-y: auto;
    overflow-x: hidden !important;
}

.cardMaxHeightVoyage {
    max-height: 1000px;
    overflow-y: auto;
    overflow-x: hidden !important;
}


.cardIndexHeight {
    /* max-height: 650px;   */
    min-height: 650px;
}

.cardMaxHeight::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #f0f0f0;
}

.cardMaxHeight::-webkit-scrollbar {
    width: 8px;
    background-color: #F5F5F5;
}

.cardMaxHeight::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #d1d1d1;
}


.cardMaxHeightVoyage::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #f0f0f0;
}

.cardMaxHeightVoyage::-webkit-scrollbar {
    width: 8px;
    background-color: #F5F5F5;
}

.cardMaxHeightVoyage::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #d1d1d1;
}

.access_forbidden {
    font-family: "Times New Roman", Times, serif;
    text-align: center;
    font-size: 50px;

}

.bootstrap-table .fixed-table-container .dropdown-menu {
    z-index: 9999;
  }

.redBG {
    width: 100%;
}


.openAttentionModal:hover {
    cursor: pointer;
}

.handle {
    cursor: grab;
}

.handle:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
}


.show {
    display: inline-block !important;
}

/* end : Booking Confirmation Style */


.loader {
    width: 200px;
    height: 60px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.circle {
    width: 20px;
    height: 20px;
    position: absolute;
    border-radius: 50%;
    background-color: #fff;
    left: 15%;
    transform-origin: 50%;
    animation: circle .5s alternate infinite ease;
}

@keyframes circle {
    0% {
        top: 60px;
        height: 5px;
        border-radius: 50px 50px 25px 25px;
        transform: scaleX(1.7);
    }

    40% {
        height: 20px;
        border-radius: 50%;
        transform: scaleX(1);
    }

    100% {
        top: 0%;
    }
}

.circle:nth-child(2) {
    left: 45%;
    animation-delay: .2s;
}

.circle:nth-child(3) {
    left: auto;
    right: 15%;
    animation-delay: .3s;
}

.shadow {
    width: 20px;
    height: 4px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, .5);
    position: absolute;
    top: 62px;
    transform-origin: 50%;
    z-index: -1;
    left: 15%;
    filter: blur(1px);
    animation: shadow .5s alternate infinite ease;
}

@keyframes shadow {
    0% {
        transform: scaleX(1.5);
    }

    40% {
        transform: scaleX(1);
        opacity: .7;
    }

    100% {
        transform: scaleX(.2);
        opacity: .4;
    }
}

.shadow:nth-child(4) {
    left: 45%;
    animation-delay: .2s
}

.shadow:nth-child(5) {
    left: auto;
    right: 15%;
    animation-delay: .3s;
}

.loader span {
    position: absolute;
    top: 75px;
    font-family: 'Lato';
    font-size: 20px;
    letter-spacing: 12px;
    color: #fff;
    left: 15%;
}

#overlay {
    position: fixed;
    /* Sit on top of the page content */
    width: 100%;
    /* Full width (cover the whole page) */
    height: 100%;
    /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgb(160, 169, 255);
    /* Black background with opacity */
    z-index: 1001;
    /* Specify a stack order in case you're using a different order for other elements */
}

.overlay1 {
    position: absolute;
    background: white;
    opacity: 1;
    width: 100%;
    /* Full width (cover the whole page) */
    height: 100%;
    /* Full height (cover the whole page) */
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1001;
    pointer-events: none;
}

.spinner {
    text-align: center;
    line-height: 100px;
    vertical-align: middle
}

.spinnerToCNDN {
    text-align: center;
    line-height: 100px;
    vertical-align: middle;
    position: relative;
    top: 150px;


}

.spinnerLoadExcelCRO {
    text-align: center;
    line-height: 250px;
    vertical-align: middle
}

.PageOverlay,.PageOverlayForAccessModal {
    position: absolute;
    background-color: rgba(233, 236, 239, 0.5);
    width: 100%;
    /* Full width (cover the whole page) */
    height: 100%;
    /* Full height (cover the whole page) */
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 10001;
    pointer-events: all;
}

.PageOverlay,.PageOverlayForPartialModal {
    position: absolute;
    background-color: rgba(233, 236, 239, 0.5);
    width: 100%;
    /* Full width (cover the whole page) */
    height: 100%;
    /* Full height (cover the whole page) */
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 10001;
    pointer-events: all;
}

.PageOverlay,.PageOverlayForINVPartialModal {
    position: absolute;
    background-color: rgba(233, 236, 239, 0.5);
    width: 100%;
    /* Full width (cover the whole page) */
    height: 100%;
    /* Full height (cover the whole page) */
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 10001;
    pointer-events: all;
}

.PageSpinner {
    border: 16px solid white;
    background-color: white;
    position: absolute;
    left: 40%;
    top: 40%;
    z-index: 1;
    height: 200px;
    width: 300px;

}

.PageSpinnerTransferFromBC {
    border: 16px solid white;
    background-color: white;
    /* position: absolute; */
    left: 40%;
    top: 40%;
    z-index: 1;
    height: 200px;
    /* width: 300px; */

}

.PageSpinnerTransferFromBR {
    border: 16px solid white;
    background-color: white;
    /* position: absolute; */
    left: 40%;
    top: 40%;
    z-index: 1;
    height: 200px;
    /* width: 300px; */

}



#TransferPartialToCNDNModal .modal-body,
#TransferToSalesInvoiceModal .modal-body,
#TransferToCreditNoteModal .modal-body,
#TransferToDebitNoteModal .modal-body {
    max-height: 650px;
    overflow: auto;
}

#SubmissionListModal .modal-xl {
    max-width: 90%;
}


#SubmissionListModal .modal-body {
    max-height: 650px;
    overflow: auto;
}






.PageSpinnerTransferFromBC .fa-spinner {
    text-align: center;
    width: 100%;
    margin-top: 50px;
}
.PageSpinnerTransferFromBR .fa-spinner {
    text-align: center;
    width: 100%;
    margin-top: 50px;
}

.PageSpinner .fa-spinner {
    text-align: center;
    width: 100%;
    margin-top: 50px;
}

.loadingText {
    margin-top: 20px;
    text-align: center;
}

.indexTableCard {
    height: 680px;
    overflow: auto;
}
.indexTableCardConsolidateINV {
    height: 600px;
    overflow: auto;
}

.form-control {
    height: 29px;
    padding: inherit;
}

.input-group-append {
    height: 28.5px;
    padding: 0cm;
}

.select2-container--krajee .select2-selection--single {
    height: 29px;
}

.select2-container--krajee .select2-selection--single .select2-selection__arrow {

    height: 27px;
}

.content-wrapper {
    overflow-y: hidden !important;
}

.input-group {
    display: flex;
}

.input-group-append {
    flex: 0 0 24px;
}

.select2-container--krajee {

    flex: 1;
}

/* .select2 {
    width:100%!important;
}

.transhipmentQuickform {
    width: 100% !important;
} */

.select2-container--krajee .select2-selection--multiple {
    width: 100%;
}

.select2-container .select2-selection--multiple {
    overflow: hidden !important;
    height: auto !important;

}

.cardIndexHeight {
    overflow: hidden !important;
    height: auto !important;
}

#dropdownMenuButton {
    border-radius: 0px;
}

.disabled-create {
    pointer-events: none;
    border: 1px solid rgb(116, 198, 135);
    background-color: rgb(116, 198, 135);
}

.disabledButtons {
    pointer-events: none;
    border: 1px solid rgb(86, 166, 253);
    background-color: rgb(86, 166, 253);
}

.LimitedCharacterBorder {
    border: 2.1px solid rgb(176, 84, 82) !important;
}

.LimitedCharacterText {
    color: rgb(176, 84, 82) !important;
}

.selfTable {
    width: 2000px;
    margin-left: auto;
    margin-right: auto;
}

.self {
    position: relative;
    bottom: 5px;
}

.text-sm .dropdown-menu {
    font-size: .8rem !important;
}

.borderless {
    pointer-events: none;
    background-color: transparent !important;
    border: 0px solid !important;
    padding: 0px !important;
}

.Auth-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
}

.Auth-form {
    width: 600px;
    height: 400px;
    box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
    padding-top: 30px;
    padding-bottom: 20px;
    border-radius: 8px;
    background-color: white;
}

.Auth-form-content {
    padding-left: 12%;
    padding-right: 12%;
}

.Auth-form-title {
    text-align: center;
    margin-bottom: 1em;
    font-size: 24px;
    color: rgb(34, 34, 34);
    font-weight: 800;
}

.bootstrap_table td {
    white-space: nowrap;
    padding:8px;
    max-width: 100px;
    text-overflow: ellipsis;
    overflow: hidden;
}
.bootstrap_table td:hover {
    overflow: visible;
    white-space: normal;
    width: auto;
}

#AccessControlTable td{
    white-space: nowrap;
    max-width: 100px;
    text-overflow: ellipsis;
    overflow: hidden;
}

#AccessControlTable td div{
    white-space: nowrap;
    max-width: 100px;
    text-overflow: ellipsis;
    overflow: hidden;
}

#AccessControlTable td:hover {
    overflow: visible;
    white-space: normal;
    width: auto;
}

#AccessControlTable td div:hover {
    overflow: visible;
    white-space: normal;
    width: auto;
}


.has-error {
    border: 1px solid #A94442 !important;

}

/* .commontable input[readonly] {
    pointer-events: none;
} */

.commontable select[disabled] {
    pointer-events: none;
}

.commontable th {
    min-width: 160px;
    max-width: 160px;
}

.voyageRouteTable th {
    min-width:  180px !important;
    max-width:  180px !important;
}


.commontable td {
    padding: 0px;
}

.commontable {
    background-color: white;
}

.has-error-select .select__control {

    border: 1px solid #A94442 !important;

}

.has-error-label {
    color: #A94442;
}

.dropdownbar>.dropdown-toggle::after {
    content: none;
}

.table_wrap {
    display: table;
    table-layout: fixed;
    width: 100%;
}

.table_wrap_inner {
    display: table-cell;
    overflow-x: auto;
    width: 100%;
}

.select__option--is-disabled {
    display: none !important;
}

.scrollable-columnchooser {
    height: auto;
    max-height: 150px;
    overflow-x: hidden;
}

.readOnlySelect .select__control {
    pointer-events: none;
   
    background: rgb(233, 236, 239) !important;
    
}

.readOnlySelect .react-select__control {
    pointer-events: none;
   
    background: rgb(233, 236, 239) !important;
    
}

.readOnlySelectVoyINV .select__control {
    pointer-events: none;
   
    background: rgb(233, 236, 239) !important;
    
}

.readOnlySelectVoyINV .react-select__control {
    pointer-events: none;
   
    background: rgb(233, 236, 239) !important;
    
}

.readOnlySelectCreditTerm .select__control {
    pointer-events: none;
   
    background: rgb(233, 236, 239) !important;
    
}

.readOnlySelectCreditTerm .react-select__control {
    pointer-events: none;
   
    background: rgb(233, 236, 239) !important;
    
}

/* .readOnlySelect .select__control {
    opacity: 0.8;
    background: rgb(233, 236, 239) !important;
    cursor: not-allowed;
}  */

.Readonly .select__control {
    pointer-events: none;
    background-color: rgb(233, 236, 239) !important;
}

.readonlyCheckbox {
    pointer-events: none;
    accent-color: rgb(233, 236, 239) !important;
}

.select-with-red-border {
    border: 1px solid red !important;
    border-radius: 5px;
}

/* .select2-results__options .select2-results__option[aria-disabled=true] {
    display: none;
} */
/* .commontable input {
    background-color: transparent !important;
    border: 0px solid !important;
    text-align: center;
}

.commontable select {
    background-color: transparent !important;
    border: 0px solid !important;
    width: 100%;
    text-align-last: center;
 
} */

#AccessControlModal .modal-xl {
    max-width: 90%;
}

#RuleSetRuleModal .modal-xl {
    max-width: 90%;
}

#ContainerModal .modal-xl {
    max-width: 90%;
}
#BR-QuotationModal .modal-xl {
    max-width: 90%;
}

#RuleSetEffectedUsersTable {
    display: inline-block;
    height: 700px;
    overflow: auto;
}

#RuleSetUsersTable {
    display: inline-block;
    height: 700px;
    overflow: auto;
}


.AllocateVoyageButton {
    height: 20px;
    left: 5px;
}

#HaulierGateOutModal .modal-xl {
    max-width: 95%;
}

.pointerEventsStyle {
    pointer-events: "none";
    background-color: "rgb(233, 236, 239)" !important
}

.my-class {
    background-color: rgba(236, 236, 236);
}

.my-class2 {
    background-color: white;
}

#PreviewAuditTrailModal .modal-body {
    height: 750px;
    overflow: auto;
}

.ApplyAll::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #f0f0f0;
}

.ApplyAll::-webkit-scrollbar {
    width: 8px;
    background-color: #F5F5F5;
}

.ApplyAll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #d1d1d1;
}

.HighLight {
    border: 3px solid rgb(255, 245, 48) !important;
    border-radius: 5px;
}

#affectedDocument .modal-xl {
    max-width: 90%;
}

#TransferToCreditNoteModal .modal-xl {
    max-width: 90%;
}

#TransferToSalesInvoiceModal .modal-xl {
    max-width: 90%;
}

#TransferToCreditNoteModal .modal-xl {
    max-width: 90%;
}

#TransferToDebitNoteModal .modal-xl {
    max-width: 90%;
}

#TransferPartialToCNDNModal .modal-xl {
    max-width: 90%;
}

.ApplyAllDocument {
    height: 300px;
    overflow-y: auto;
}

.ApplyAllDocument::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #f0f0f0;
}

.ApplyAllDocument::-webkit-scrollbar {
    width: 8px;
    background-color: #F5F5F5;
}

.ApplyAllDocument::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #d1d1d1;
}
.disabledAccess{
   opacity: 0.6; 
  cursor: not-allowed !important; 
  pointer-events: none !important; 
}
.disabledAccessCheckbox {
  opacity: 0.5; /* reduce opacity to make it look faded */
  cursor: not-allowed !important; /* change cursor to show it's not clickable */
  pointer-events: none;
}

.modal-dialog .overlay {
    /* position: relative; */
    background-color : rgb(255 255 255 / 70%);
}
  
.modal-dialog .loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.modal-dialog .fa-spinner {
    font-size: 3rem;
}

.btn-orange{
    color: #fff;
    background-color:orange;
    border-color:orange;
    box-shadow: none;
  }
  
  .btn-orange:hover {
    background-color: orange;
    border-color: orange;
    color: white;
  }
  
  .btn-red:hover {
    background-color: rgb(220, 53, 69);
    border-color: rgb(220, 53, 69);
    color: white;
  }
  
  .btn-red{
    background-color: rgb(220, 53, 69);
    border-color: rgb(220, 53, 69);
    color: white;
  }
  

@media screen and (max-width: 1700px) {
    .leftList {
    left: auto !important;
    right: 150px;
    }
    .leftList2{
    left: auto !important;
    right: 150px;
    }
}
/* Styling for the draggable items */
.draggable-item {
    border: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 10px;
    background-color: #fff;
  }
  
  /* Styling for the drag handle */
  .drag-handle {
    cursor: grab;
    padding: 5px;
    background-color: #eee;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-right: 5px;
  }
  
  /* Styling for the droppable area */
  .droppable-area {
    background-color: #f0f0f0;
    padding: 10px;
    border: 1px dashed #ccc;
    min-height: 50px;
    margin-top: 10px;
  }

  .select2-selection__choice {
    color: black !important;  /* Change text color if needed */
  }

  .NoPermissionLink{
    pointer-events: none;
    color:grey
  }
  
.pointerEventsStyleDocDate {
    pointer-events: none;
    background-color: rgb(233, 236, 239) !important
}

 